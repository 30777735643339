import form from "./schemas/form";
import validations from "./schemas/validations";
import {useEffect, useState} from "react";
import initialValues from "./schemas/initialValues";
import axios from "axios";
import {API_URL} from "../../../config";
import {capitalizeFirstLetter} from "../../../helpers/helpers";
import {useParams} from 'react-router-dom';

const useEditUser = () => {

    const { id } = useParams();

    const {formId, formField} = form;
    const currentValidation = validations;

    const [values, setValues] = useState(initialValues);

    const [isLoading, setIsLoading] = useState(true);

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    useEffect(() => {

        const init = async () => {

            setIsLoading(true);

            await loadRecord();

            setIsLoading(false);
        };

        init();

    }, []);

    const loadRecord = async () => {

        try {

            const response = await axios.get(`${API_URL}/users/${id}`);

            setValues(response.data);

        } catch (error) {

            setSnackbarConfig({
                message: "Ocurrió un error cargando el perfil",
                icon: 'cancel',
                title: 'Usuarios',
                color: 'warning'
            });

            setShow(true);

        }

    };

    const handleSubmit = async (values, actions) => {

        try {

            // Enviar datos a API
            await axios.put(
                `${API_URL}/users/${id}`,
                {...values}
            );

            setSnackbarConfig({
                message: 'Usuario actualizado exitosamente',
                icon: 'notifications',
                title: 'Usuarios',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Usuarios',
                color: 'warning'
            });

            setShow(true);

        }

        actions.setSubmitting(false);

    };

    return {
        formId,
        formField,
        currentValidation,
        values,
        isLoading,
        snackbarConfig,
        show,
        toggleSnackbar,
        handleSubmit
    }
};

export default useEditUser;
