/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import FormField from "../../../../../components/FormField";
import MDBox from "../../../../../components/MDBox";
import React from "react";
// Material Dashboard 2 PRO React components


function EditUserForm({formData, roles}) {

    const {formField, values, errors, touched} = formData;

    const {statement_url} = formField;
    const {
        statement_url: statement_urlV,
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={statement_url.type}
                        label={statement_url.label}
                        name={statement_url.name}
                        value={statement_urlV}
                        error={errors.statement_url && touched.statement_url}
                        success={ statement_urlV && statement_urlV.length > 0 && !errors.statement_url}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default EditUserForm;
